body {
    
    font-family: Arial, sans-serif;
    background-color: black;
    color: white;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: black;
    
 
   
}

.logo {
    font-size: 2.5rem;
    font-weight: bold;
    color: #852007;
   
}
.searchcontainer {
    display: flex;
    align-items: center;
    position: relative; 
    width: 100%;
    justify-content: center;
    margin-top: 80px;
   
}


.searchcontainer div{
    border: 1px solid white;
    width: 60%;
    display: flex;
    justify-content: space-between;
    
}

.searchinput {
    width: 100%; 
    height: 120px;
    padding: 10px;
   border: none;
    border-radius: 4px;
    background-color: black;
    color: white;
    font-size: 3rem;
    outline: none;
}

.App{
    background-color: black;
   
    height: 100vh;

}

.searchbutton {
  
  
    padding: 10px;
    border: none; 
    background-color: black;
    cursor: pointer;
 
}
.App button{
    background: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
   
    width: 100%;
    display: flex;
    align-items: right ;
    justify-content: end;
    
   
}
.searchbutton img {
    width: 80px;
    height: 80px;
    padding: 10px;

    margin-right: 40px;
}

.closebutton{
    width: 100%;
    background-color: black;
    padding: 10px;
    display: flex;
    justify-content: end;
    border: none;
    margin-top: 100px;
  }

.closeLink{
    border: 1px solid grey;
    padding: 10px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    margin-top: 5px;
}