.footer {
  bottom: 0;
  width: 100%;
  background-color: black; 
}

.footerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px; /* Reduced padding for smaller screens */
}

.footerIcons {
  display: flex;
  gap: 15px; /* Reduced gap for smaller screens */
}

.iconButton:hover {
  transform: scale(1.1); /* Slightly reduced scale for smaller screens */
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out; 
}

.icon {
  width: 40px; /* Reduced size for smaller screens */
  height: 40px; /* Reduced size for smaller screens */
}

.newsletter {
  display: flex;
  align-items: center;
  gap: 5px; /* Reduced gap for smaller screens */
  margin-right: 5px; /* Reduced margin for smaller screens */
}

.emailSubscription {
  display: flex;
  align-items: center;
  gap: 5px;
}

.emailLabel {
  font-size: 14px; /* Reduced font size for smaller screens */
  color: grey;
  margin-right: 5px; /* Reduced margin for smaller screens */
  margin-top: 5px;
}

.emailInput {
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  color: #fff;
  outline: none;
  padding: 5px; /* Reduced padding for smaller screens */
}

.emailInput::placeholder {
  color: #fff;
  font-size: 16px; /* Reduced font size for placeholder */
}

.subscribeButton {
  background: none;
  border: none;
  cursor: pointer;
}

.blinkingArrow {
  animation: blink 1s infinite;
  width: 20px; /* Reduced size for smaller screens */
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.errorMessage {
  color: red;
  font-size: 12px; 
  margin-left: 5px; 
}

.thankYouMessage {
  font-size: 14px; 
}

.footerSections {
  display: flex;
  justify-content: space-between;
  margin-top: 10px; 
  flex-wrap: wrap;
}

.footerSection {
  flex: 1;
  min-width: 150px; 
  margin-right: 5px;
}

.footerSection h2 {
  font-size: 16px; 
  margin-bottom: 10px;
  color: rgb(67, 66, 66);
  text-align: left;
}

.footerSection p {
  margin: 5px 0;
  text-align: left;
  line-height: 1.5; 
  color: grey;
  font-size: 0.9em; 
}

.footerBottom {
  text-align: center;
  padding: 10px; 
  color: white;
}

.footerBottom1 {
  text-align: center;
  padding: 10px; 
  color: rgb(72, 70, 70);
}

@media (max-width: 400px) {
  .footerTop {
    flex-direction: column;
    gap: 10px; 
  }

  .footerSections {
    flex-direction: column;
    align-items: center;
  }
}
