

.App {
  font-family: Arial, sans-serif;
  color: #333;
  overflow-x: hidden;
}

.Appheader {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.slideshow {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.slideshowimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlayText {
  position: absolute;
  bottom: 50%;
  right: 0;
  transform: translateY(50%);
  color: var(--themeColor);
  font-size: 3rem; /* Adjust as needed */
  font-weight: bold;
  text-align: right; /* Aligns text to the right edge */
  width: auto; /* Adjust width as needed */
  padding: 20px; /* Add padding if needed */
}

.videoSection {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.videoOverlayText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  z-index: 1;
}

.knowMoreButton {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  color: white;
  background-color: var(--themeColor);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.knowMoreButton:hover {
  background-color: var(--themeColor);
}

.imageSection {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url('./dark0.jpg') no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: left;
}

.imageOverlayText {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  color: rgb(167, 162, 162);
  font-size: 3em;
  font-weight: bold;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
}

.fourthBlock {
  background: url('./futuristic-moon-background (1).jpg') no-repeat center center/cover;
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.fourthBlock h1 {
  color: #d6d1d1;
  margin: 0;
  margin-bottom: 20px;
  font-size: 3rem;
}

.serviceList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 100%;
  margin-bottom: 50px;
}

.serviceColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.serviceItem {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 250px;
  height: 150px;
  transition: background-color 0.3s ease;
  overflow: hidden;
  max-width: 100%;
  text-align: center;
  color: white;
}

.serviceItem:hover {
  background-color: var(--themeColor);
  color: #d6d1d1;
}

.icon {
  width: 50px;
  height: 50px;
  display: block;
}

.fifthBlock {
  width: 100%;
  background: url('./Safety First .png') no-repeat center center / cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.sixthBlock {
  text-align: center;
  min-height: 100vh;
  background-image: url(./theme.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: rgb(130, 121, 121);
  font-size: 2rem;
  padding: 0;
  width: 100%;
}

.clientImages {
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  max-width: 1000px;
  margin: 0 auto;
}

.clientImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
}

.clientImage img {
  width: 80%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.imageOverlayText{
  display: flex;
  justify-content: left;
}

@media (max-width: 600px) {
  .videoOverlayText, .imageOverlayText, .overlayText {
    font-size: 1.5rem;
    padding: 5px;
    width: 90%;
  }

.Appheader{
  width: 100%;
  overflow: hidden;
}
  .fourthBlock h1{
    margin-top: 20px;
    font-size: 2rem;
    padding: 5px;
  }
  .serviceItem {
    width: 200px;
    height: 130px;
  }

  .clientImages {
    grid-template-columns: 1fr;
    gap: 0.25rem;
  }

  .clientImage {
   
  }

  .clientImage img {
    width: 100%;
    max-width: 50px;
  }
}

@media (max-width: 400px) {
  .videoOverlayText, .imageOverlayText, .overlayText {
    font-size: 1rem;
    padding: 2px;
  }

  .serviceItem {
    width: 180px;
    height: 120px;
  }

  .knowMoreButton {
    font-size: 0.8rem;
    padding: 8px 16px;
  }
}
