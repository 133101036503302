

body {
  color: black;
  overflow-x: hidden;
  background-color: black; 
}

.companyProfile {
  width: 100%;
  font-family: Arial, sans-serif;
}

.imageSection {
  position: relative;
  width: 100%;
}

.fullWidthImage, .fullWidthImage1 {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  animation: fadeIn 2s ease-in-out;
}

.overlayText {
  font-size: 5rem;
  margin: 0;
  width: 100%;
  text-align: left;
  margin-left: 250px;
  animation: slideInLeft 2s ease-in-out;
}

.overlaySubText {
  font-size: 1.5rem;
  margin: 10px 0 0 0;
}

.aboutSection {
  padding: 20px;
  animation: fadeIn 2s ease-in-out;
  background-color: black;
}

.text {
  margin: 0 auto;
  text-align: left;
}

.text h2 {
  font-size: 4rem;
  margin-bottom: 10px;
  color: rgb(229, 220, 220);
}

.text p {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: rgb(143, 136, 136);
}

.icons {
  width: 64px;
  height: 64px;
  vertical-align: middle;
  margin-right: 8px;
}

.visionSection, .missionSection, .valuesSection {
  margin-top: 30px;
}

.visionSection h2, .missionSection h2, .valuesSection h2 {
  font-size: 4rem;
  margin-bottom: 10px;
}

.valuesSection ul {
  list-style-type: none;
  padding: 0;
  color: rgb(143, 136, 136);
}

.valuesSection li {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.block2 {
  margin: 20px 0;
  animation: fadeIn 2s ease-in-out;
}

.taglineSection {
  background: #000;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.taglineTextContainer {
  max-width: 800px;
  margin-top: 250px;
  position: relative;
  display: flex;
  align-items: center;
}

.scrollingText {
  display: inline-block;
  animation: scroll-left 180s linear infinite; 
  white-space: nowrap;
 
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .overlayText {
    font-size: 2rem;
  }

  .overlaySubText {
    font-size: 1rem;
  }

  .text h2 {
    font-size: 1.5rem;
  }

  .text p {
    font-size: 0.875rem;
  }

  .visionSection h2, .missionSection h2, .valuesSection h2 {
    font-size: 1.25rem;
  }

  .valuesSection li {
    font-size: 0.875rem;
  }
}

.block4 {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: url('./dark.jpg') no-repeat center center/cover;
}

.block4::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent, rgba(0, 0, 0, 0.8));
  z-index: 1;
}

.block4 .overlayText {
  position: relative;
  z-index: 2;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 10rem;
  white-space: nowrap;
  animation: scroll-left 40s linear infinite;
}

@media (max-width: 768px) {
  .block4 .overlayText {
    font-size: 2rem;
  }
}

@media (min-width: 150%) {
  .block4 .overlayText {
    font-size: 6rem;
  }
}

@media (min-width: 150%) {
  .overlayText {
    font-size: 4.5rem;
  }

  .overlaySubText {
    font-size: 2.25rem;
  }

  .text h2 {
    font-size: 3rem;
  }

  .text p {
    font-size: 1.5rem;
  }

  .visionSection h2, .missionSection h2, .valuesSection h2 {
    font-size: 2.25rem;
  }

  .valuesSection li {
    font-size: 1.5rem;
  }
}
