html {
  scroll-behavior: smooth;
}

.container {
  width: 100%;
  font-family: Arial, sans-serif;
  background-color: #000;
}

.hero {
  position: relative;
  text-align: center;
  color: #ffffff;
  text-shadow: 10ch;
}

.heroImage {
  width: 100%;
  height: 100vh;
  object-fit: cover; 
  animation: slideIn 2s ease-in;
}

.heroText {
  position: absolute;
  top: 60%;
left: 29%;
  text-align: right;
  transform: translate(-50%, -50%);
  font-size: 4em;
  font-weight: 600;
  
}

.services {
  padding: 20px;
  background: #000;
  transition: background 0.5s ease;
}

.services h2 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #080707;
  text-align: center;
  margin-bottom: 10px;
}

.serviceGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.serviceItem {
  text-align: center;
  width: 400px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s, background 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px solid lightgray;
  
}

.serviceItem:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.serviceItem img {
  width: 280px;
  height: 280px;
  object-fit: cover;
  border-radius: 10px;
}

.serviceItem p {
  font-size: 1.5em;
  margin: 0;
}

.testimonials {
  padding: 20px;
  background: #000;
  transition: background 0.5s ease;
}

.testimonials h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
  animation: fadeIn 2s ease-in;
}

.testimonialGrid {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.testimonialItem {
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border: 2px solid grey;
  height: 300px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
  animation: slideUp 1.5s ease-in-out;
}

.testimonialItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.testimonialImage {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 auto 10px;
}

.testimonialName {
  font-weight: bold;
  color: #000;
  margin-bottom: 5px;
}

.testimonialComment {
  font-style: italic;
  color: #000;
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

body {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@media (max-width: 1200px) {
  .heroText {
    font-size: 3em;
  }

  .services h2 {
    font-size: 1.75em;
  }

  .serviceItem {
    flex: 1 1 calc(50% - 20px);
    height: 200px;
  }

  .testimonialItem {
    width: 48%;
  }
}

@media (max-width: 992px) {
  .heroText {
    font-size: 2.5em;
  }

  .services h2 {
    font-size: 1.5em;
  }

  .serviceItem {
    flex: 1 1 calc(50% - 20px);
    height: 200px;
  }

  .testimonialItem {
    width: 48%;
  }
}

@media (max-width: 768px) {
  .heroText {
    font-size: 2em;
  }

  .services h2 {
    font-size: 1.25em;
  }

  .serviceItem {
    flex: 1 1 calc(50% - 20px);
    height: 200px;
  }

  .testimonialItem {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .heroText {
    font-size: 1.5em;
  }

  .services h2 {
    font-size: 1em;
  }

  .serviceItem {
    flex: 1 1 100%;
    height: 200px;
  }

  .testimonialItem {
    width: 100%;
  }
}
