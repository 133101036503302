/* Base Styles */

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px; 
  box-sizing: border-box;
  z-index: 1000;
}

.menuButton,
.searchButton {
  background: transparent; 
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menuIcon,
.searchIcon {
  width: 60px; 
  height: 60px; 
  margin-right: 10px; 
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.menuIcon:hover,
.searchIcon:hover {
  transform: scale(1.2);
}

.iconsContainer {
  display: flex;
  align-items: center;
}

.logo {
  width: 300px; 
  height: auto;
}


@media (max-width: 400px) {
  .header {
    padding: 10px; 
  }

  .menuIcon,
  .searchIcon {
    width: 30px; 
    height: 30px;
    margin-right: 5px; 
  }

  .logo {
    width: 150px; 
    height: auto;
  }

  .iconsContainer {
    flex-direction:row; 
    align-items: flex-start; 
  }
}
