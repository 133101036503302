/* body {
  background-color: rgb(37, 36, 36);
  margin: 0;
}

.teamdetails {
  text-align: center;
  margin: 20px auto;
  color: white;
  padding: 20px;
  margin-top: 120px;
  animation: fadeIn 1.5s ease-in-out;
}

.teammembers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: 1px solid lightgray;
  padding: 10px;
  margin-top: 140px;
  background-color: rgb(37, 36, 36);
}

.teammember {
  margin: 20px;
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 10px;
  width: 250px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: grey;
  color: rgb(225, 220, 220);
  animation: slideUp 1s ease-in-out;
}

.teammember:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.memberimage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 20px;
 
}

.teammember h2 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.teammember p {
  color: #f0f0f0;
}

@media (max-width: 768px) {
  .teammember {
    width: calc(50% - 40px);
  }
}

@media (max-width: 576px) {
  .teammember {
    width: calc(100% - 40px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
} */
body {
  background-color: rgb(37, 36, 36);
  margin: 0;
}

.teamSection {
  background-image: url('./team.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.teamOverlay {
 
  padding: 50px;
  border-radius: 10px;
  text-align: center;
}

.teamOverlay h1 {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
}
